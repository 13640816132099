import React, { useEffect, useState } from 'react'
import { Grid, Typography, Card, CardContent, CardActions, Button } from '@mui/material';
import { Link } from "react-router-dom";
import { Box } from '@mui/system';
import { Helmet } from 'react-helmet';
import Markdown from 'react-markdown'
import moment from 'moment';

import Loading from './../components/Loading';
import { url } from './../functions.js'

export default function Home() {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [dataAnalysis, setDataAnalysis] = useState([]);
   
    useEffect(() => {
      setIsLoading(true);
      fetch(url('/s/news/recap'))
      .then(
        res => res.json())
      .then(json => {
        setData(json);
        setIsLoading(false);
      });
      fetch(url('/s/analysis'))
      .then(res => res.json())
      .then(json => {
        setDataAnalysis(json);
        setIsLoading(false);
      });
    },[]);
  
    return (
      <Box>
  
        <Helmet>
          <title>ontons.com</title>
          <meta name="description" content="news, crypto, summary, recap, ai" />
        </Helmet>
        <Loading show={isLoading} />

        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={12} md={12}>
          
            <Card>
              <Typography component="div" variant="h4" ml={2} mt={2}>
                News based crypto sentiment
              </Typography>
              <Typography variant="caption" component="div" sx={{ color: "text.secondary" }} ml={2}>
                Updated: {moment(data.Time).fromNow()}
              </Typography>
              <CardContent sx={{ mt: -3, mb: -3 }}>
                <Typography component="div" variant="body2">
                  <Markdown>{data.Text}</Markdown>
                </Typography>
              </CardContent>
              <CardActions>
              <Link to="/news" style={{ textDecoration: "none" }}>
                <Button size="small" color="primary">
                  Read news...
                </Button>
              </Link>

              </CardActions>
            </Card>
  
          </Grid>
     
          {dataAnalysis?.Data?.map((entry, i) => (
            <Grid item xs={12} md={12} key={entry.ID}>
              <Card>
                <Typography component="div" variant="h4" ml={2} mt={2}>{entry.Description}</Typography>
                <Typography variant="caption" component="div" sx={{ color: 'text.secondary' }} ml={2}>
                Updated: {moment(dataAnalysis.Time).fromNow()}
                </Typography>
                <CardContent sx={{ mt: -3, mb: -3 }}>
                  <Typography component="div" variant="body2">
                      <Markdown>{entry.Text}</Markdown>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}

          
        </Grid>
      </Box>
    )
}