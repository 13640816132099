import './App.css';
import { CssBaseline, Container, Button, Box, Link }  from '@mui/material';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import { themeOptions } from "./ThemeOptions";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useState } from 'react'
import CookieConsent from "react-cookie-consent";

import Header from './components/Header';
import RatesLine from './components/RatesLine.js';
import Footer from './components/Footer.js';

import Home from './pages/Home';
import News from './pages/News';
import Events from './pages/Events';
import Alerts from './pages/Alerts';
import Arbitrage from './pages/Arbitrage';
import Monitor from './pages/Monitor';
import Stock from './pages/stocks/Stock';
import StocksLowVolatilitySnp500 from './pages/stocks/LowVolatilitySNP500';
import OverboughtOversoldSNP500 from './pages/stocks/OverboughtOversoldSNP500';

import UserContext from "./contexts/UserContext";

let theme = createTheme(themeOptions);
theme = responsiveFontSizes(theme);


function App() {
  const [user, setUser] = useState({});
  const value = { user, setUser };

  return (
    <UserContext.Provider value={value}>
      <Helmet>
        <title>App Title</title>
        <meta name="description" content="App Description" />
        <meta name="theme-color" content="#008f68" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <CookieConsent style={{ background: "#402E1F" }} ButtonComponent={Button}>This website uses cookies to enhance the user experience.</CookieConsent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}
        >
        <BrowserRouter>
          <Header/>
          
          <Container component="main" sx={{ mb: 2 }} maxWidth="lg">
            <RatesLine />
              <Routes>
                <Route path='/' element={<Home />}></Route>
                <Route path='/news' element={<News />}></Route>
                <Route path='/news/:p' element={<News />}></Route>
                <Route path='/events' element={<Events />}></Route>
                <Route path='/alerts' element={<Alerts />}></Route>
                <Route path='/monitor' element={<Monitor />}></Route>
                <Route path='/arbitrage' element={<Arbitrage />}></Route>
                <Route path='/stock/:symbol' element={<Stock />}></Route>
                <Route path='/stocks/low-volatility-snp500' element={<StocksLowVolatilitySnp500 />}></Route>
                <Route path='/stocks/overbought-oversold-snp500' element={<OverboughtOversoldSNP500 />}></Route>
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
          </Container>
        </BrowserRouter>
        <Footer/>
        </Box>
      </ThemeProvider>
    </UserContext.Provider>
  );
}

export default App;